<template>
  <div class="content min_box" v-wechat-title="title">
    <nav-bar :title="title" />
    <div
      v-if="params.type == 1"
      class="detail"
      v-html="detail.impression_description"
    ></div>
    <div v-else>
      <div class="cc">
        <div class="titles">
          <div class="title">{{ detail.news_name }}</div>
          <div class="time">{{ detail.created_at }}</div>
        </div>
        <div class="center" style="padding-bottom: 110rpx">
          <div v-html="detail.news_detail"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import navBar from "@/components/nav/nav";
import { impression_detail, server_news_info } from "@/api/http";

export default {
  name: "Vue",
  data() {
    return {
      title: "详情",
      detail: "",
      params: "",
    };
  },

  components: {
    navBar,
  },

  created() {
    this.params = this.$route.params;
    if (this.params.type == 1) {
      impression_detail({
        region_code: this.$route.params.code,
        impression_cate: this.params.cate,
        type: 0,
      }).then((res) => {
        this.detail = res.data.impression_detail;
        let arr = this.detail.impression_description;
        this.detail.impression_description = arr
          ? arr.replace(/\<img/gi, '<img style="width:100%"')
          : arr;
      });
    } else if (this.params.type == 2) {
        this.title = '招商政策详情'
      server_news_info({
        id: this.params.cate,
      }).then((res) => {
        this.detail = res.data.server_news;
      });
    }
  },
  methods: {},
};
</script>
<style lang='less' scoped>
.detail {
  padding: 20px;
}
.cc {
  padding: 20px 40px;
  .titles {
    margin-bottom: 20px;
    .title {
      font-size: 1.5rem;
      color: #333;
      font-weight: bold;
    }
    .time {
      margin-top: 10px;
      color: #888;
      font-size: .8rem;
    }
  }
}
</style>